@import "../../style/theme.scss";

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 1100;
  background-color: rgba(0, 0, 0, 0.5);
}
.popup_inner {
  position: absolute;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 15%;
  //	margin: auto;
  background: white;
  margin: 0;
  text-align: center;

  /**display: flex;
	flex-direction: column;
	justify-content: center;
  align-items: center;**/

  .popup-textbox {
    display: flex;
    flex-direction: column;
    align-items: center;

    > p {
      font-family: $font-family-main;
      margin-left: 40px;
      font-family: $font-family-main;
    }

    .popup-pdf-title {
      font-size: $font-size-large;
      color: $font-color-primary;
      font-weight: $font-bold;
    }
  }

  .popup-banner-top {
    .popup-close-btn {
      float: right;
      width: 40px;
      height: 40px;
      background-color: transparent;
      border: none;
      margin: 5px;
      cursor: pointer;
    }
  }

  .pdf-btn-img {
    width: 50px;
  }

  .popup-btn-group {
    display: flex;
    justify-content: space-evenly;
    margin: 0;
    position: relative;
    //	height: 100%;

    .export-pdf-btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: transparent;
      border: $box-border;
      padding: 10px;
      margin-top: 20px;
      width: 125px;
      height: 125px;
      cursor: pointer;

      &:hover {
        background: rgba(0, 0, 0, 0.2);
        transition: 0.3s;
      }

      > p {
        max-width: 120px;
        font-family: $font-family-main;
        color: $font-color-primary;
        font-weight: $font-bold;
      }
    }
  }
}
