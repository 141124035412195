.loader {
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 12000;
  background: rgba(255, 255, 255, 0.3);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  img {
    width: 38px;
    height: 38px;
  }
}
