@import "../style/theme.scss";

.chart-content-container.about {
  overflow: visible;
}

.chart-container-text {
  display: flex;
}
.icon-text-outer-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  max-width: 1000px;

  &.transport {
    grid-template-columns: repeat(3, 1fr);
    overflow: hidden;

    .cvt-icon {
      width: 100%;
      max-width: 180px;
      height: auto;

      @media print {
        max-width: 90px;
      }
    }
  }

  @include media("<=1500px") {
    grid-template-columns: repeat(3, 1fr);
  }

  &.transport {
    @include media("<=1000px") {
      grid-template-columns: repeat(3, 1fr);
      max-width: 650px;
    }
  }

  @include media("<=980px") {
    grid-template-columns: repeat(2, 1fr);
    //	max-width: 300px;
  }

  @media print {
    grid-template-columns: repeat(3, 1fr);

    .cvt-icon {
      width: 100%;
      max-width: 90px;
      height: auto;
    }
  }

  .iconText--container,
  .icon-text-inner-container {
    text-align: center;
    max-width: 200px;

    &.transport {
      max-width: 280px;
      @include media("<=phone") {
        max-width: 150px;
      }
    }

    @media print {
      max-width: auto;
    }
  }
}

.iconText--content,
.climate-vision-iconTitle {
  font-family: $font-family-main;
  color: $font-color-primary;
  font-size: 1.1rem;
  font-weight: 500;
  text-align: center;
}
.cvt-icon {
  width: 100%;
  max-width: 170px;
  height: auto;
}
.climate-vision-subtitle {
  color: $font-color-primary;
  font-size: 1.333rem;
  font-weight: 500;
  margin-bottom: 30px;

  &.transport {
    color: $font-color-primary;
    font-weight: $font-bold;
  }

  @include media("<=1300px") {
    max-width: 60vw;
  }

  @include media("<=980px") {
    max-width: 50vw;
  }

  @media print {
    max-width: 1000px;
  }
}

.climate-vision-title {
  font-size: $font-size-large;
  color: $font-color-primary;
  font-weight: $font-bold;
  padding: 20px 0;

  &.inactive {
    padding: 0;
  }
}

.chartContainer {
  &.inactive {
    display: none;
  }
}

.total-companies-circle {
  background-color: $green;
  height: 100px;
  width: 100px;
  line-height: 50px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;

  .total-companies-scope0 {
    font-size: $font-size-large;
    color: white;
    font-weight: $font-bold;
  }
}

.climate-vision-transport-text-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: $font-size-small;
  padding: 0 15px;

  .climate-vision-transport-text {
    &.bottom {
      font-weight: $font-bold;
    }
  }
}

.climate-vision-subtext {
  font-family: $font-family-main;
  font-size: $font-size-regular;
  color: $font-color-primary;
  margin: 30px 0;
  max-width: 900px;
  white-space: pre-wrap;
  span {
    font-family: $font-family-main !important;
  }
  @media print {
    font-size: $font-size-x-regular;
  }
}

.climate-vision-transport-text {
  text-align: left;
  font-size: $font-size-regular;
  color: $font-color-primary;
  margin: 0.25em 0;
}

.total-companies-container {
  margin: 0 20px;
  @include media("<=1200px") {
    display: flex;
    flex-direction: column;
  }
}

.iconText--container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .total-companies-title {
    font-family: $font-family-main;
    color: $font-color-primary;
    font-size: 1.333rem;
    font-weight: 500;
    text-align: center;

    @include media("<tablet") {
      max-width: 170px;
    }

    &.number {
      font-size: 1.4rem;
      color: $font-color-primary;
    }
  }
}

.about {
  display: flex;
  justify-content: space-between;

  .climate-vision-subtext {
    max-width: 540px;
  }

  .info-legend-tooltip {
    position: relative;
    display: inline-block;
    background-color: $purple;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 10px;
    color: #fff;
    user-select: none;
    cursor: pointer;
    font-size: $font-size-small;

    .info-legend-tooltip--triangle {
      visibility: hidden;
      height: 10px;
      width: 350px;
      position: absolute;
      left: -300px;
      display: flex;
      justify-content: flex-end;
      padding-right: 24px;
      padding-top: 5px;

      > span {
        width: 30px;
        height: 10px;
      }
    }
    .info-legend-tooltip--text {
      position: absolute;
      z-index: 10001;
      width: 350px;
      background-color: $purple;
      padding: $box-padding;
      font-family: $font-family-main;
      font-size: 1.2rem;
      visibility: hidden;
      top: 32px;
      left: -300px;
      overflow: visible;

      @include media("<=tablet") {
        left: -175px;
      }
    }
  }

  .info-legend-tooltip:hover .info-legend-tooltip--text {
    visibility: visible;
  }

  .info-legend-tooltip:hover .info-legend-tooltip--triangle {
    visibility: visible;
  }

  .climate-vision-icon--container {
    display: flex;
    align-items: flex-start;

    @include media("<=1250px") {
      justify-content: space-around;
    }

    @include media("<=phone") {
      justify-content: center;
    }

    .total-companies-title {
      font-family: $font-family-main;
      color: $font-color-primary;
      font-size: 1.333rem;
      font-weight: 500;
      text-align: center;

      @include media("<tablet") {
        max-width: 170px;
      }

      &.number {
        font-size: 1.4rem;
        color: $font-color-primary;
      }
    }
  }

  @include media("<=1250px") {
    display: flex;
    flex-direction: column;
  }
}

.key-result-footnote {
  &.about {
    max-width: 110px;
    text-align: center;
    font-size: $font-size-small;
    font-weight: $font-bold;
  }
}

#more {
  display: none;
}

.read-more-btn {
  border: none;
  background-color: transparent;
  font-family: $font-family-main;
  font-size: $font-size-regular;
  color: $font-color-primary;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: #ff876f;
  }
}
