@import "../../style/theme.scss";

.closing-button {
  @media print {
    display: none;
  }
}

.chart-content-container {
  border: 1px solid #f7f7fb;
  background: #f7f7fb;
  overflow: hidden;
  max-width: 1000px;
  border: 0;
  padding: $box-padding;
  margin: $box-margin;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-family: $font-family-main;
  background-color: $lightPurple;

  @include media("<=1250px") {
    width: 70vw;
    @media print {
      width: auto;
    }
  }

  @include media("<=desktop") {
    width: 100%;
  }

  .chart-top-menu {
    @media print {
      &.none {
        display: none;
      }
    }

    display: flex;
    justify-content: space-between;

    p {
      font-size: $font-size-large;
      color: $font-color-primary;
      font-weight: $font-bold;
      margin: 0 0 20px;

      @media print {
        margin: 0;
      }
    }
    > button {
      background: none;
      border: none;
    }

    button:focus {
      outline: 0;
    }

    .icon {
      z-index: 0;
      > svg {
        height: 15px;
        color: $font-color-primary;
      }
    }
  }

  .chart-inner-content {
    &.closed {
      display: none;
    }
  }
}
