@import "../style/theme.scss";

.scopeboxes-container {
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 250px;
  border: $box-border;
  padding: $box-padding;
  @include media("<=tablet") {
    margin: 0;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  @include media("<=phone") {
    margin: 0;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  @media print {
    min-width: none;
    margin: 0;
  }

  .print-boxes {
    text-align: center;

    @media print {
      text-align: left;
    }

    > h5 {
      font-size: $font-size-small;
      margin: 20px 0;
      font-weight: $font-bold;
      color: $font-color-primary;
      font-family: $font-family-main;

      @media print {
        margin: 0;
      }
    }

    > p {
      font-size: $font-size-x-regular;
      color: $font-color-primary;
      font-family: $font-family-main;
      max-width: 350px;
      color: $font-color-primary;
      text-align: left;

      @media print {
        max-width: 700px;
        font-size: $font-size-small;
      }
    }
  }
}
