@import "../../style/theme.scss";

// Cover page

.cover-page-container {
  margin: 0cm;
  font-family: $font-family-main;

  .kp-logo-img {
    width: 100px;
    margin: 40px;
    position: absolute;
  }

  .cover-page-img {
    background-image: url("../../static/pdf/cover2.jpeg");
    background-repeat: no-repeat, repeat;
    height: 700px;
    width: 100%;
    margin: 0;
    padding: 0;
    right: 0;
    left: 0;
    position: absolute;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
    margin-top: 300px;
  }
}

.cover-title-container {
  height: 100%;
  z-index: 3000;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: $pdf-pink;
  font-weight: $font-bold;
  position: absolute;
  margin-right: 100px;
  width: 300px;
  // padding-top: 250px;

  .main-title {
    text-transform: uppercase;
    font-size: $font-size-x-large;
    color: $font-color-primary;
    max-width: 100px;
    font-family: $font-family-main;
    font-weight: $font-bold;
    margin: 40px 20px;

    &.large {
      font-size: 1.7rem;
    }
  }

  .pdf-sub-title {
    color: $font-color-primary;
    margin: 20px;
    font-size: $font-size-regular;
  }
  .pdf-year-title {
    color: $font-color-primary;
    margin: 20px;
    font-size: $font-size-x-regular;
  }
}

// Introduction Page

.introduction-page-container {
  .introduction-title-container {
    height: 950px;
    z-index: 3000;
    top: 80px;
    bottom: 0;
    right: 0;
    background-color: $purple;
    font-family: $font-family-main;
    position: absolute;
    width: 50%;
    padding-top: 250px;

    &.large {
      width: 70%;
      padding-top: 10px;

      > p {
        font-size: $font-size-small;
      }
    }
    .introduction-main-title {
      max-width: 200px;
      margin: 20px;
      color: white;
      font-size: $font-size-regular;
      font-weight: $font-bold;
    }
    > p {
      // dangerous
      color: white;
      margin: 20px;
    }
  }

  .introduction-page-img {
    background-image: url("../../static/pdf/introduction-bg.jpeg");
    background-repeat: no-repeat, repeat;
    height: 950px;
    width: 100%;
    right: 0;
    left: 0;
    position: absolute;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
    margin-top: 80px;
  }
}

.cover-underline {
  border: 1.5px solid $dark-coral;
  max-width: 100px;
  margin-left: 22px;
}

// Method page

.method-page-container {
  font-family: $font-family-main;
  color: $font-color-primary;

  .method-subTitle {
    margin: 20px;
    font-weight: $font-bold;
  }

  .method-title {
    margin: 20px;
  }

  .method-text-content {
    margin: 20px;
    font-size: $font-size-small;
  }

  .scope-box-container {
    .scopeboxes-img {
      overflow: hidden;
      height: 0;
      // display: none;
    }
    max-height: 100px;
  }
}
