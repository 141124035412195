@import "../../style/theme.scss";

.pdf-btn {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  cursor: pointer;
  background-color: $purple;
  border: none;
  color: white;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  align-items: center;
  font-weight: $font-bold;

  .pdf-img {
    width: 35px;
    margin: 10px;
    text-align: center;
    justify-self: center;
  }
  @include media("<=tablet") {
    display: none;
  }
}
