@import "../style/theme.scss";

.checkbox--wrapper {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  user-select: none;
  margin-right: 30px;
  // margin-top: 20px;
  margin-top: 0px;

  @include media("<phone") {
    margin-right: 0;
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  .checkbox-text {
    display: inline-block;
    vertical-align: bottom;
    font-size: 1.2rem;
    font-weight: 500;
    color: $font-color-primary;
    margin-top: 5px;
    user-select: none;
  }

  input {
    appearance: none;
  }

  .checkbox {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background: white;
    border: 3px solid $purple;
    border-radius: 2px;

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 6px;
      width: 5px;
      height: 13px;
      border: solid $purple;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  input:checked ~ .checkbox {
    &:after {
      display: block;
    }
  }
}
