@import "../style/theme.scss";

.front-page-container {
  margin: 20px 10px 0 ($side-menu-width + 50px);
  font-family: $font-family-main;
  text-align: left;
  overflow: hidden;
  float: left;

  @include media("<=1335px") {
    padding: 0;
    margin-left: ($side-menu-width + 20px);
    float: none;
  }

  @include media("<=922px") {
    margin-left: ($side-menu-width);
  }

  @include media("<=tablet") {
    padding: 40px 10px 0 10px;
    margin: 0;
  }

  @include media("<=phone") {
    padding: 30px 10px 0 10px;
  }

  @media print {
    margin: 0;
    padding: 0;
  }

  .no-data {
    font-size: $font-size-large;
    color: $font-color-primary;
    font-weight: $font-bold;
    margin: 20px 0;
    min-height: 100px;
  }

  .region-partner-img {
    width: 100%;
    max-width: 1000px;
    height: auto;

    @include media("<=1250px") {
      max-width: 70vw;
    }
    @include media("<=desktop") {
      max-width: 100%;
    }
    @include media("<=tablet") {
      width: 100%;
    }
    @media print {
      width: auto;
    }
  }

  .title-container {
    display: flex;
    flex-direction: column;
  }

  .content-option {
    display: flex;
    // margin-bottom: 40px;
    margin-bottom: 10px;
    justify-content: space-between;
    align-items: flex-start;
    max-height: 43px;
    @include media("<=600px") {
      max-height: initial;
    }

    .left-selection {
      display: flex;
      flex-direction: row;
    }

    @include media("<desktop") {
      align-items: baseline;
      max-height: 80px;
    }

    .current-year-selector {
      z-index: 1000;
      font-size: $font-size-regular;
      color: $font-color-primary;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      margin-right: 20px;

      // @include media("<=600px") {
      //   flex-direction: column;
      // }
      @include media("<desktop") {
        flex-direction: column;
      }

      > span {
        padding-right: 2px;
      }

      select {
        color: $font-color-primary;
        font-family: $font-family-main;
        font-size: $font-size-regular;
        border: 1px solid $font-color-primary;
        padding: 5px 20px 5px 10px;
        margin-left: 10px;

        @include media("<=600px") {
          margin-left: auto;
          margin-top: 10px;
        }
      }
    }
  }

  .header {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    .main-title {
      text-transform: uppercase;
      font-size: $font-size-x-large;
      color: $font-color-primary;
      display: inline-block;

      @include media("<=phone") {
        font-size: $font-size-phone-main-title;
      }

      &.pdfView {
        display: none;
      }
    }
    .push-right {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      color: #1d3c34;
      font-size: 14px;

      a {
        color: #1d3c34;
        &:link {
          color: inherit;
        }
      }

      img {
        width: 100%;
        height: 32px;
      }
    }
  }
}

.pdf-btn-container {
  bottom: 0;
  right: 0;
  margin: 10px;
  position: fixed;
  z-index: 1000;
}

.barchart-in-frontpage {
  &.inactive {
    display: none;
  }
}
