@import "../style/theme.scss";

.dropdown-container {
  display: inline-block;
  cursor: pointer;
  font-family: $font-family-main;

  .company-btn-dropdown {
    background: none;
    border: 1px solid #ebebeb;
    box-shadow: 5px 5px 5px #8080802e;
    text-align: left;
    font-family: $font-family-main;
    font-weight: 500;
    font-size: $font-size-regular;
    color: $font-color-primary;
    padding: 20px;
    min-width: 350px;
    min-height: 60px;

    @include media("<phone") {
      min-width: auto;
    }

    &:focus {
      outline: 0;
    }

    .icon {
      margin-left: 280px;
      margin-top: -10px;
      > svg {
        height: 15px;
        color: $font-color-primary;
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;
    min-width: 349px;
    background-color: white;
    position: absolute;
    justify-content: left;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    list-style-type: none;

    @include media("<phone") {
      min-width: auto;
    }

    li {
      padding: 8px 16px;
      border-bottom: 1px solid #e5e5e5;

      .link-btn {
        text-decoration: none;
        color: $font-color-primary;
      }
    }

    li:hover {
      background-color: $purple;
      .link-btn {
        color: white;
      }
    }
  }
}