@import "../../style/theme.scss";

.piechart--controll-line {
  @media print {
    display: none;
  }
}

.chart-content-container {
  .chart-inner-content {
    .piechart--controll-line {
      display: flex;
      margin-top: 20px;

      @media print {
        display: none;
      }

      @include media("<=1000px") {
        flex-direction: column;
        align-items: flex-start;
      }

      .checkbox--wrapper {
        align-self: center;
        margin-left: 60px;

        @include media("<=1000px") {
          margin-left: 0;
          align-self: flex-start;
          margin-top: 20px;
        }
      }
    }

    .avi-chart-tile {
      .avi-chart-tile-content {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: stretch;
        align-content: stretch;
        .pie-chart {
          width: 500px;
          flex: 3;
          align-self: center;
        }
        .legend {
          flex: 1;
        }
      }
      .avi-chart-tile-footer {
        display: none;
      }
    }
  }
}
