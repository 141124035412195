@import "../style/theme.scss";

.sidemenu-container {
  height: 100%;
  width: $side-menu-width;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  transition: all 0.2s;
  z-index: 10000;
  background-color: $purple;
  scrollbar-color: $purple;


  @include media("<=tablet") {
    width: 0;
    &::before {
      display: block;
      content: "";
      position: fixed;
      left: 0;
      background: #000;
      opacity: 0.4;
      transition: 0.2s;
      z-index: 999;
    }
    &.open {
      width: $side-menu-tablet;
      &::before {
        @include size(100vw, 100%);
        left: $side-menu-tablet;
      }
    }
  }

  @include media("<=phone") {
    &.open {
      width: $side-menu-phone;
      &::before {
        left: $side-menu-phone;
      }
    }
  }

  .sidemenu-bg {
    height: 100%;
    background-color: $purple;
    text-align: left;
    padding-left: 20px;

    .menu-container {
      position: relative;
      border-radius: 70px;
      margin-right: -50px;

      a {
        display: block;
        cursor: pointer;
      }

      &:hover,
      &.active {
        background-color: white;
        a {
          color: $font-color-primary;
          .icon > svg {
            .path3833 {
              fill: white;
            }
            fill: $purple;
          }
        }
      }

      .icon {
        display: inline-block;
        vertical-align: middle;
        width: 36px;
        margin: 0 15px;

        svg {
          fill: white;
        }

        @include media("<=tablet") {
          margin: 0 10px;
        }
      }

      .menu-title {
        font-family: $font-family-main;
        background: none;
        border: none;
        color: white;
        font-size: $font-size-regular;
        cursor: pointer;
        text-align: left;
        padding: 25px 10px;
        font-weight: bold;
        text-decoration: none;

        .region-title {
          display: inline-block;
          word-wrap: break-word;
          width: 170px;
        }

        .region-logo {
          vertical-align: top;
        }

        @include media("<=tablet") {
          font-size: 1.2rem;
        }
      }
    }

    .kp_logo {
      width: 100px;
      padding: 30px;
    }
  }
}
