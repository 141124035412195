.PDFChart-container-content {
  .pdf-pie-chart-div {
    .dc-html-legend {
      margin-left: -40px;
      margin-top: -15px;

      .legend-item {
        flex: 1 0 160px;
        font-weight: 500;
        font-size: 15px;
        margin-bottom: 15px;

        .dc-legend-item-color {
          height: 10px;
          width: 10px;
        }
      }
    }

    > p,
    a,
    span {
      font-size: 12px;
      height: 55px;
      position: absolute;
    }
  }
}
