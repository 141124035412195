@import "../style/theme.scss";

.key-business-result-content {
  margin-bottom: 80px;

  .circle-container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;

    @include media("<950px") {
      align-items: center;
      flex-direction: column;

      @media print {
        flex-direction: row;
      }
    }

    .circle-text-img-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      // margin: 50px 150px 30px 20px;

      @include media("<phone") {
        margin: 50px auto;
      }
      .info-legend-tooltip {
        position: absolute;
        display: inline-block;
        background-color: $purple;
        padding: 2px 8px;
        border-radius: 10px;
        color: #fff;
        user-select: none;
        cursor: pointer;
        font-size: $font-size-small;
        font-weight: 500;
        margin-left: 10px;

        .info-legend-tooltip--triangle {
          visibility: hidden;
          height: 10px;
          width: 350px;
          position: absolute;
          left: -300px;
          display: flex;
          justify-content: flex-end;
          padding-right: 24px;
          padding-top: 5px;

          > span {
            width: 30px;
            height: 10px;
          }
        }
        .info-legend-tooltip--text {
          position: absolute;
          z-index: 10001;
          width: 350px;
          background-color: $purple;
          padding: $box-padding;
          font-family: $font-family-main;
          font-size: 1.2rem;
          font-weight: 500;
          visibility: hidden;
          top: 32px;
          left: -300px;
          overflow: visible;

          @include media("<=tablet") {
            left: -175px;
          }
        }
      }

      .info-legend-tooltip:hover .info-legend-tooltip--text {
        visibility: visible;
      }

      .info-legend-tooltip:hover .info-legend-tooltip--triangle {
        visibility: visible;
      }

      .key-business-icon-title {
        font-family: $font-family-main;
        font-size: 1.2rem;
        line-height: 1.1;
        font-weight: $font-bold;
        color: $font-color-primary;
        max-width: 100px;
        text-align: center;

        sup {
          font-size: medium;
        }
      }

      div.key-business-icon-title {
        margin: 19px 0;
      }

      .circle-pink-bg {
        width: 90px;
        height: 90px;
        background-color: $pink-overlay;

        border-radius: 50%;
        line-height: 20px;
        text-align: center;

        .circle-key-number {
          font-size: $font-size-regular;
          color: $font-color-primary;
          font-weight: $font-bold;
        }
        .kbc-icon {
          width: 90px;
          // margin-top: -20px;
        }
      }

      .total-employees {
        color: $purple;
        font-weight: 500;
        font-size: 1.2rem;
        transform: translateY(70px);
      }
    }
  }
}
