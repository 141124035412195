@import "../style/theme.scss";

.key-result-content {
  padding-top: 15px;
  display: flex;
  flex-direction: row;

  @include media("<=980px") {
    display: inline-block;

    @media print {
      display: flex;
      padding-top: 10px;
      flex-direction: row;
    }
  }

  .key-result-inner-content {
    margin: 20px 50px 20px 10px;

    @media print {
      margin: 10px 40px 10px 3px;
    }

    .key-result-sub-title {
      margin-right: 50px;
      color: $font-color-primary;
      font-size: 1rem;
      font-weight: 500;
    }

    .key-result-number {
      font-size: $font-size-large;
      font-weight: $font-bold;
    }
  }
}
.key-result-footnote {
  font-size: 0.9rem;
  color: $font-color-primary;

  &.inactive {
    display: none;
  }
}
