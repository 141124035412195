@import "./colors.scss";
@import "./fonts.scss";

@mixin form-control-style {
  display: block;
  width: 100%;
  padding: 10px;
  border: 1px $grey solid;
  color: $black;
  background-color: $white;
  font-size: 14px;
  border-radius: 0px;
  outline: none;

  &:focus {
    border: 2px solid darken($navbar-color, 15%);
  }
}

// ----------------------------
// Font mixin
// ----------------------------

@mixin main-font-style(
  $weight: $font-weight-regular,
  $size: $font-size-xx-large,
  $color: $font-color-main
) {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: $weight;
  font-size: $size;
  color: $color;
}
// ----------------------------
// Headline mixin
// ----------------------------
@mixin headline(
  $weight: $font-weight-bold,
  $size: $font-size-xx-large,
  $color: $font-color-main
) {
  @include main-font-style($weight: $weight, $size: $size, $color: $color);
  margin: 0;
  padding: 0;
}
// ----------------------------
// Size mixin

// ----------------------------
@mixin size($width: $width, $height: $height) {
  height: $height;
  width: $width;
}
@mixin square($size) {
  @include size($size, $size);
}
// ----------------------------
// Pseudo mixin
// ----------------------------
@mixin pseudo($display: block, $pos: absolute, $content: "") {
  content: $content;
  display: $display;
  position: $pos;
}
// ----------------------------
// Absolute positioning mixin
// ----------------------------
@mixin absolute-pos($top: false, $right: false, $bottom: false, $left: false) {
  position: absolute;
  @if $top {
    top: $top;
  }
  @if $right {
    right: $right;
  }
  @if $bottom {
    bottom: $bottom;
  }
  @if $left {
    left: $left;
  }
}
// ----------------------------
// Button mixin
// ----------------------------
@mixin btn-common-properties(
  $weight: $font-weight-bold,
  $size: $font-size-x-large,
  $bgColor: $white,
  $txtColor: $font-color-main
) {
  @include main-font-style($weight: $weight, $size: $size);
  @include btn-color($bgColor: $bgColor, $txtColor: $txtColor);
  position: relative;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  text-decoration: none;
  padding: 12px 28px;
  border: 0 solid transparent;
  border-radius: 4px;
  cursor: pointer;
  // &:hover {
  // @include btn-color($bgColor: $bgColor, $txtColor: $txtColor);
  // }
}
@mixin btn-color($bgColor: $btn-primary, $txtColor: $font-color-main) {
  background: $bgColor;
  color: $txtColor;
  a {
    color: $txtColor;
  }
}
