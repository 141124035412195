@import url("https://fonts.googleapis.com/css?family=Lato:300,400,600,700,900&subset=latin-ext");
// @import url("/Klimapartnere/static/fonts/Neutra2Text_book.woff2");
// @import url("/Klimapartnere/static/fonts/Neutra2Text_bold.woff2");

@font-face {
  font-family: "Neutraface 2 Text";
  src: url("../static/fonts/Neutra2Text_bold.woff2") format("woff2"),
    url("../static/fonts/Neutra2Text_bold.woff") format("woff");
  font-style: bold;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: "Neutraface 2 Text";
  src: url("../static/fonts/Neutra2Text_bold-Lining.woff2") format("woff2"),
    url("../static/fonts/Neutra2Text_bold-Lining.woff") format("woff");
  font-style: bold;
  font-weight: 600;
  font-stretch: normal;
}

@font-face {
  font-family: "Neutraface 2 Text";
  src: url("../static/fonts/Neutra2Text_book.woff2") format("woff2"),
    url("../static/fonts/Neutra2Text_book.woff") format("woff");
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "Neutraface 2 Text";
  src: url("../static/fonts/Neutra2Text_book_italic.woff2") format("woff2"),
    url("../static/fonts/Neutra2Text_book_italic.woff") format("woff");
  font-style: italic;
  font-weight: 400i;
  font-stretch: normal;
}

@font-face {
  font-family: "Neutraface 2 Text";
  src: url("../static/fonts/Neutra2Text_book-Lining.woff2") format("woff2"),
    url("../static/fonts/Neutra2Text_book-Lining.woff") format("woff");
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
}

@font-face {
  font-family: "Neutraface 2 Text";
  src: url("../static/fonts/Neutra2Text_book_italic-Lining.woff2")
      format("woff2"),
    url("../static/fonts/Neutra2Text_book_italic-Lining.woff") format("woff");
  font-style: italic;
  font-weight: 500i;
  font-stretch: normal;
}

// ----------------------------
// Font Family
//-----------------------------

$font-family-main: "Neutraface 2 Text", sans-serif, arial;
// Overskrift er Neutraface 2 text.

// ----------------------------
// Font Sizes
// ----------------------------

$font-size-x-small: 12px;
$font-size-small: 15px;
$font-size-x-regular: 1rem;
$font-size-regular: 1.333rem;
$font-size-large: 1.5rem;
$font-size-phone-main-title: 1.8rem;
$font-size-x-large: 2.333rem;
$font-size-xx-large: 50px;

// ----------------------------
// Font Weights (5kg)====(5kg)
// ----------------------------

$font-bold: 700;
