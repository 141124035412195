@import "../style/theme.scss";

.main-layout-cross {
  @include square(38px);
  position: absolute;
  right: 20px;

  @include media("<phone") {
    right: 10px;
  }

  @media print {
    display: none;
    margin: 0;
    padding: 0;
  }

  .icon {
    margin: 20px;
    > svg {
      height: 30px;
      fill: $purple;
    }
  }
}

@include media("<=tablet") {
  .sidemenu-hamburger {
    @include square(38px);
    position: absolute;
    left: 20px;
    text-align: right;
    cursor: pointer;

    div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      & span {
        @include size(30px, 4px);
        display: block;
        border-radius: 2px;
        background: $purple;
        margin: 4px auto;
        opacity: 1;
        pointer-events: none;
      }
    }
  }
}

@media print {
  .sidemenu-container {
    display: none;
  }
}
