.pdf-page {
  width: 21cm;
  height: 29.6cm; // bad...
  position: relative;
  overflow: hidden;
  padding: 1cm 1cm 1cm 2cm;
  page-break-after: always !important;

  &.margin-free {
    margin: 0;
    padding: 0;
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .pdf-page {
      height: 29.4cm;
    }
  }
}
