@import "../../style/theme.scss";

.time-barchart-container {
  &.inactive {
    display: none;
  }
  .dc-chart {
    height: 650px;
    > svg {
      overflow: visible;
    }
  }

  .dc-legend {
    .dc-legend-item text {
      font-family: $font-family-main;
      text-transform: none;
      font-size: 1rem;
      font-weight: normal;
      fill: $purple;
    }
  }

  .axis.x > text {
    display: none;
  }

  .time-barchart-subtitle {
    margin-right: 50px;
    color: $font-color-primary;
    font-size: 1.333rem;
    font-weight: 500;
  }
}
