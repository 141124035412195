@import "../style/theme.scss";

.taksonomi-container {
  p,
  h3 {
    color: $font-color-primary;
    font-family: $font-family-main;
    line-height: 1.1;
  }

  h3 {
    font-size: 1.33rem;
  }
  p {
    font-size: 1.33rem;
  }

  .taksonomi-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 40px;
    @include media("<desktop") {
      grid-template-columns: 1fr;
      gap: 40px 0;
    }
    @include media("<=tablet") {
      grid-template-columns: 1fr 1fr;
      gap: 0 30px;
    }
    @include media("<=640px") {
      grid-template-columns: 1fr;
      gap: 40px 0;
    }

    .traffic-light--container {
      display: flex;
      span {
        @include square(80px);
        display: inline-block;
        border-radius: 50%;
        background: #dadded;
        &:not(:last-child) {
          margin-right: 20px;
          @include media("<=desktop") {
            margin-right: 15px;
          }
        }
        // red light
        &:nth-child(3n + 1).active {
          background: #ff876f;
        }
        // yellow light
        &:nth-child(3n + 2).active {
          background: #ffc566;
        }
        // green light
        &:nth-child(3n + 3).active {
          background: #259b85;
        }
      }
    }
  }
}
