@import "../style/theme.scss";

.data-circle-container {
  display: flex;

  @include media("<=1000px") {
    flex-direction: column;

    @media print {
      flex-direction: row;
    }
  }

  .data-circle-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: $font-size-small;
    font-weight: $font-bold;
    color: $font-color-primary;

    .data-circle {
      width: 170px;
      height: 170px;
      border-radius: 50%;
      font-size: 20px;
      text-align: center;
      margin: 5px 30px;
      color: #fff;

      &._2018 {
        color: $purple;
        line-height: 140px;
        border: 15px solid $purple;
        font-size: 25px;

        @media print {
          font-size: 20px;
          line-height: 100px;
          border: 10px solid $purple;
          width: 120px;
          height: 120px;
        }
      }

      &._2019 {
        color: $purple;
        line-height: 140px;
        border: 15px solid $coral;
        font-size: 25px;

        @media print {
          font-size: 20px;
          line-height: 100px;
          border: 10px solid $coral;
          width: 120px;
          height: 120px;
        }
      }

      &._2020 {
        color: $purple;
        line-height: 140px;
        border: 15px solid $yellow;
        font-size: 25px;

        @media print {
          font-size: 20px;
          line-height: 100px;
          border: 10px solid $yellow;
          width: 120px;
          height: 120px;
        }
      }
    }
  }
}
