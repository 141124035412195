@import "../../style/theme.scss";
.scope-toggle-container {
  @media print {
    display: none;
  }
}
.barchart-inner-content {
  .chart-title {
    font-size: 0px;
  }
  &.tilted .bar-chart-grouped svg g g:first-child .tick text {
    transform: rotate(30deg);
    text-anchor: start;
  }
}

.chart-title {
  font-family: $font-family-main;
  font-weight: $font-bold;
  color: $font-color-primary;
}

.legend-item {
  color: $font-color-primary;
  font-weight: 500;
}

.barchart-container-outer {
  .chart-inner-container {
    .klimagassutslipp-content {
      .chart-title {
        font-size: $font-size-large;
        color: $font-color-primary;
        font-weight: $font-bold;
        margin: 0 0 20px 0;
      }
      .data-circle-container {
        display: flex;

        @include media("<=1000px") {
          flex-direction: column;
        }
        @media print {
          flex-direction: row;
        }

        .data-circle-inner {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 1rem;
          font-weight: $font-bold;
          color: $font-color-primary;

          .data-circle {
            width: 170px;
            height: 170px;
            border-radius: 50%;
            font-size: 20px;
            line-height: 170px;
            text-align: center;
            margin: 5px 30px;

            &.purple {
              background: $purple;
              color: #fff;
            }
            &.pink {
              background: $coral;
              color: $purple;
            }
            &.yellow {
              background: $yellow;
              color: $purple;
            }
          }
          @media print {
            .data-circle {
              width: 120px;
              height: 120px;
              line-height: 120px;
            }
          }
        }
      }
    }
  }

  // YearLegend

  .year-legend-container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    margin-right: 55px;

    .year-legend-inner-content {
      display: flex;
      color: $font-color-primary;
      font-family: $font-family-main;
      font-weight: $font-bold;
    }

    .legend-color {
      height: 15px;
      width: 15px;
      margin-right: 10px;
      margin-left: 10px;
      border-radius: 50%;

      &.purple {
        background-color: $purple;
      }

      &.pink {
        background-color: $coral;
      }

      &.yellow {
        background-color: $yellow;
      }
    }
  }
}
