@import "../style/theme.scss";

.about-KP-page-container {
  max-width: 65vw;
  margin-right: 10px;
  font-family: $font-family-main;

  @include media("<=tablet") {
    max-width: 100%;
    margin-right: 0;
  }

  .kp-title {
    text-transform: uppercase;
    font-size: 2.333rem;
    color: $font-color-primary;
    font-weight: $font-bold;
  }

  .about-KP-description {
    font-size: $font-size-large;
    margin: 50px 0;
    font-weight: $font-bold;
    color: $font-color-primary;
  }

  .about-KP-text {
    font-size: $font-size-regular;
    color: $font-color-primary;
  }
}
.about-KP-top-box {
  margin: 50px 0;
}

.scope-box-container {
  display: flex;
  margin-top: 50px;

  @media print {
    display: inline-block;
  }

  @include media("<=1253px") {
    flex-direction: column;
  }

  .scopeboxes-container {
    margin-left: 0;

    @include media("<=phone") {
      padding: 10px;
      margin: 0;
      &:not(:first-child) {
        margin-top: 30px;
      }
    }
  }
}
.pdf-text-and-icon-container {
  display: flex;
  cursor: pointer;

  .icon {
    margin-left: 20px;

    > svg {
      height: 15px;
    }
  }

  .display-pdf-text {
    font-size: $font-size-regular;
    color: $font-color-primary;
    text-decoration: underline;
  }
}

.pdf-display-box {
  border: 1px solid $green;
  background-color: $green-overlay;
  padding: 10px;

  a {
    display: block;
  }
}
.calculation-scope {
  background-color: $green-overlay;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin-top: 40px;

  @include media("<=1253px") {
    flex-direction: column;
  }

  .calculation-title-description-box {
    padding-left: 15px;

    .calculation-scope-title {
      font-size: 15px;
      margin: 20px 0;
      font-weight: $font-bold;
      color: $font-color-primary;
      font-family: $font-family-main;
    }

    .calculation-scope-description {
      font-family: $font-family-main;

      color: $font-color-primary;
      text-align: left;
    }
  }
}
