// ----------------------------
//  sidemenu size
// ----------------------------

$side-menu-width: 260px;
$side-menu-phone: 80vw;
$side-menu-tablet: 70vw;

// ----------------------------
//  (Chart) Containers
// ----------------------------

$box-margin: 20px 0 20px 0;
$box-min-width: 60vw;
$box-padding: 20px;
$box-border: 1px solid $purple;

$box-max-width-tablet: 65vw;
$box-min-width-tablet: 20vw;

$box-max-width-phone: 200vw;
$box-min-width-phone: 30vw;

// ----------------------------
//  Toggles
// ----------------------------
