$purple: rgb(70, 78, 163);
$dark-purple: rgb(24, 28, 75);
$lightPurple: rgba(58, 69, 154, 0.09);

// Blues

$blue: rgb(152, 216, 229);

// yellows

$orange: rgb(253, 134, 80);
$yellow: rgb(255, 197, 102);

// Pinks

$coral: rgb(255, 180, 153);
$pink: rgb(255, 226, 217);
$dark-coral: rgb(250, 119, 104);
$pdf-pink: rgb(253, 135, 111, 0.38);
$pink-overlay: rgb(253, 135, 111, 0.5);

// Grey

$light-grey: #dbe1ea;
$font-grey: #5a5b6f;
$grey: rgb(215, 209, 204);

// Green

$deep-green: rgb(0, 167, 146);
$green: rgb(82, 186, 172);
$green: rgb(28, 176, 155);
$green-overlay: rgb(28, 176, 155, 0.1);

// Overlay

$overlay: rgba(255, 255, 255, 0.6);

$font-color-primary: #3a469a;
