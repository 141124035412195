@import "../../style/theme.scss";

.single-pdf {
  .about {
    display: flex;
    // padding: 0;

    .climate-vision-text--container {
      display: none;
    }

    .climate-vision-icon--container {
      width: 100%;
      flex-direction: row;
      justify-content: space-around;

      .iconText--container .info-legend-tooltip {
        display: none;
      }

      .total-companies-title {
        margin: 0;
      }
    }
  }

  .cvt-icon {
    width: 100%;
    max-width: 100px;
    height: auto;
  }

  .aggregated-climate-vision {
    .chart-inner-container {
      .climate-vision-subtitle {
        max-width: none;
        margin-bottom: 10px;
      }
      .icon-text-outer-container {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }

  .chartContainer {
    .chart-inner-container {
      .climate-vision-subtitle {
        max-width: none;
        margin-bottom: 10px;
      }
      .icon-text-outer-container {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }

  .data-circle-container {
    justify-content: space-between;
    .data-circle-inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 1rem;
      font-weight: $font-bold;
      color: $font-color-primary;

      .data-circle {
        width: 170px;
        height: 170px;
        border-radius: 50%;
        font-size: 20px;
        line-height: 170px;
        text-align: center;
        margin: 5px 30px;

        &._2018 {
          color: $purple;
          line-height: 140px;
          border: 15px solid $purple;
          font-size: 25px;

          @media print {
            font-size: 20px;
            line-height: 100px;
            border: 10px solid $purple;
            width: 120px;
            height: 120px;
          }
        }

        &._2019 {
          color: $purple;
          line-height: 140px;
          border: 15px solid $coral;
          font-size: 25px;

          @media print {
            font-size: 20px;
            line-height: 100px;
            border: 10px solid $coral;
            width: 120px;
            height: 120px;
          }
        }

        &._2020 {
          color: $purple;
          line-height: 140px;
          border: 15px solid $yellow;
          font-size: 25px;

          @media print {
            font-size: 20px;
            line-height: 100px;
            border: 10px solid $yellow;
            width: 120px;
            height: 120px;
          }
        }

        &.purple {
          background: $purple;
        }
        &.pink {
          background: $coral;
        }
        &.yellow {
          background: $yellow;
        }
      }
      @media print {
        .data-circle {
          width: 120px;
          height: 120px;
          line-height: 120px;
        }
      }
    }
  }

  .region-img--container {
    height: 100%;
    position: relative;
    img {
      border: none;
      width: 100%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      object-fit: contain;
    }
  }

  .key-result-content {
    .key-result-inner-content {
      margin-top: 0;
      margin-bottom: 0;
      .key-result-sub-title {
        margin-bottom: 0;
        margin-right: 0;
      }
      .key-result-number {
        margin-top: 10px;
      }
    }
  }

  .key-result-footnote {
    display: none;
  }

  .barchart-container-outer {
    max-height: 280px;
    .chart-title {
      max-width: none !important;
    }

    .bar-chart-grouped svg g g:first-child {
      font-size: 10px;
    }
    .chart-title {
      font-size: 0px;
    }
    .bar-chart-grouped svg g g {
      font-size: 10px;
    }

    .barlabel {
      font-size: 0;
    }

    .barchart-container-outer {
      .klimagassutslipp-content,
      .key-result-footnote {
        display: none;
      }
    }

    .chart-tile-header {
      height: 10px;
      padding: 0px 10px;
    }
  }

  .chart-content-container .chart-inner-content .chart-inner-container {
    .key-business-result-content {
      .circle-container {
        flex-direction: row;
      }
    }
  }

  .chart-content-container
    .chart-inner-content
    .avi-chart-tile
    .avi-chart-tile-content
    .pie-chart {
    flex: 1 1 150px;
  }

  .pdf-pie-chart-div {
    .chart-title {
      max-width: none;

      .avi-chart.legend .dc-html-legend .legend-item {
        flex: 1 0 160px;
        .dc-legend-item-color {
          height: 15px;
          width: 15px;
        }
        .dc-legend-item-label {
          font-size: 14px;
        }
      }
    }
  }

  .time-barchart-container {
    .chart-inner-container {
      .time-barchart-subtitle {
        display: none;
      }
      .chart-title {
        max-width: none !important;
        .avi-chart-tile-content {
          .dc-legend {
            margin-top: 50px;
            .dc-legend-item text {
              font-family: $font-family-main;
              text-transform: none;
              font-size: 1rem;
              font-weight: normal;
              fill: $purple;
            }
          }
          .avi-chart {
            height: 800px;
          }
        }
      }
    }
  }
}

// PDF-export single business

.pdf-export-single-business {
  .chart-content-container.about {
    .climate-vision-icon--container .iconText--container .info-legend-tooltip {
      display: none;
    }
  }
  .chartContainer {
    .chart-inner-container {
      .climate-vision-subtitle {
        max-width: none;
        margin-bottom: 10px;
      }
      .icon-text-outer-container {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }

  .chart-content-container
    .chart-inner-content
    .avi-chart-tile
    .avi-chart-tile-content
    .pie-chart {
    flex: 1 1 150px;
  }

  .pdf-pie-chart-div {
    .chart-title {
      max-width: none;

      .avi-chart.legend .dc-html-legend .legend-item {
        flex: 1 0 160px;
        .dc-legend-item-color {
          height: 15px;
          width: 15px;
        }
        .dc-legend-item-label {
          font-size: 14px;
        }
      }
    }
  }

  .data-circle-container {
    justify-content: space-between;
    .data-circle-inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 1rem;
      font-weight: $font-bold;
      color: $font-color-primary;

      .data-circle {
        width: 170px;
        height: 170px;
        border-radius: 50%;
        font-size: 20px;
        line-height: 170px;
        text-align: center;
        margin: 5px 30px;

        &._2018 {
          color: $purple;
          line-height: 140px;
          border: 15px solid $purple;
          font-size: 25px;

          @media print {
            font-size: 20px;
            line-height: 100px;
            border: 10px solid $purple;
            width: 120px;
            height: 120px;
          }
        }

        &._2019 {
          color: $purple;
          line-height: 140px;
          border: 15px solid $coral;
          font-size: 25px;

          @media print {
            font-size: 20px;
            line-height: 100px;
            border: 10px solid $coral;
            width: 120px;
            height: 120px;
          }
        }

        &._2020 {
          color: $purple;
          line-height: 140px;
          border: 15px solid $yellow;
          font-size: 25px;

          @media print {
            font-size: 20px;
            line-height: 100px;
            border: 10px solid $yellow;
            width: 120px;
            height: 120px;
          }
        }

        &.purple {
          background: $purple;
          color: #fff;
        }
        &.pink {
          background: $coral;
          color: $purple;
        }
        &.yellow {
          background: $yellow;
          color: $purple;
        }
      }
      @media print {
        .data-circle {
          width: 120px;
          height: 120px;
          line-height: 120px;
        }
      }
    }
  }

  .chart-content-container
    .chart-inner-content
    .chart-inner-container
    .key-business-result-content
    .circle-container
    .circle-text-img-content
    .key-business-icon-title
    .info-legend-tooltip {
    display: none;
  }

  .barchart-container-outer {
    max-height: 280px;
    .chart-title {
      max-width: none !important;
    }

    .bar-chart-grouped svg g g:first-child {
      font-size: 10px;
    }
    .chart-title {
      font-size: 0px;
    }
    .bar-chart-grouped svg g g {
      font-size: 10px;
    }

    .barlabel {
      font-size: 0;
    }
    .barchart-container-outer {
      .year-legend-container {
        margin-bottom: 20px;
      }
      .klimagassutslipp-content,
      .key-result-footnote {
        display: none;
      }
    }

    .chart-tile-header {
      height: 10px;
      padding: 0px 10px;
    }
  }
  .time-barchart-container {
    .chart-inner-container {
      .time-barchart-subtitle {
        display: none;
      }
      .chart-title {
        max-width: none !important;
        .avi-chart-tile-content {
          .dc-legend {
            margin-top: 50px;
            .dc-legend-item text {
              font-family: $font-family-main;
              text-transform: none;
              font-size: 1rem;
              font-weight: normal;
              fill: $purple;
            }
          }
          .avi-chart {
            height: 800px;
            svg {
              overflow: visible;

              g.row-chart-stacked {
                overflow: visible;
              }
            }
          }
        }
      }
    }
  }
}
