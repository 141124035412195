@use "../style/fonts";
@import "../style/theme.scss";

.faq--wrapper {
  font-family: fonts.$font-family-main;
  text-align: left;
  margin-left: 320px;
  max-width: 900px;

  @include media("<tablet") {
      margin-left: 20px;
      padding-top: 40px;
  }
}
