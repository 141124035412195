@import "../style/theme.scss";

.toggle-container {
  display: inline-block;
  position: relative;
  background: $light-grey;
  padding: 5px;
  border-radius: 50px;

  @include media("<desktop") {
    border-radius: 0px;
    background: transparent;
  }

  @include media("<phone") {
    background: transparent;
    padding: 0;
  }

  &.main-menu {
    background: $purple;
    padding: 10px;

    @include media("<phone") {
      background: transparent;
      padding: 0;
    }

    label {
      color: white;
      padding: 15px 70px;
      font-size: 1.33rem;

      @include media("<882px") {
        padding: 15px 50px;
      }

      @include media("<tablet") {
        padding: 10px 30px;
      }

      @include media("<phone") {
        &:not(:last-child) {
          border-right: 1px solid #000;
        }
      }

      @include media("<phone") {
        color: $font-color-primary;
        padding: 15px;
        position: relative;
      }

      &.active {
        color: $font-color-primary;
        @include media("<phone") {
          border-bottom: 3px solid #fa7768;
        }
      }
    }
  }

  input[type="radio"] {
    display: none;
  }

  label {
    background: transparent;
    display: inline-block;
    padding: 10px 20px;
    text-align: center;
    color: $font-color-primary;
    cursor: pointer;
    font-family: $font-family-main;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 50px;

    @include media("<desktop") {
      background: transparent;
      // display: inline;
      border-radius: 0;
      padding: 15px;

      // &:not(:last-child) {
      //   border-right: 1px solid #000;
      // }
    }

    @include media("<phone") {
      color: $font-color-primary;
      // padding: 15px;
      padding: 5px;
      position: relative;
    }

    &.active {
      background: white;
      @include media("<desktop") {
        background: transparent;
        border-bottom: 3px solid #fa7768;
      }
    }

    .tooltip { //changed
      // display: none;
      position: absolute;
      z-index: 10001;
      width: 350px;
      background-color: $purple;
      padding: $box-padding;
      font-family: $font-family-main;
      font-size: 1.2rem;
      visibility: hidden;
      //top: 32px;
      top: 64px;
      // left: -300px;
      left: 0px;
      overflow: visible;
      color: #fff;
      text-align: left;

      @include media("<=tablet") {
        left: -175px;
      }

      @include media("<phone") {
        display: none;
      }
    }
  }
  label:hover .tooltip {
    visibility: visible;
  }
}
