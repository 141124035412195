@import "../style/theme.scss";

.main-toggle-component {
  z-index: 1000;
  overflow: visible;
}

.main-toggle-dropdown-component-container {
  text-align: right;
  font-family: $font-family-main;
  // font-weight: $font-bold;
  color: $purple;
  user-select: none;
  font-size: $font-size-regular;

  @include media("<=600px") {
    // padding-top: 10px;
    text-align: left;
    max-width: 200px;
    // word-spacing: 30000px;
  }

  > span {
    cursor: pointer;
  }

  > button {
    background: none;
    border: none;
    transform: translateY(10px);

    > span {
      height: 30px;
      width: 30px;
    }
  }

  @include media("<phone") {
    font-size: 1rem;
    max-width: 100px;
  }
}

.main-toggle-menu-container {
  overflow: hidden;
  // width: 640px;
  width: 400px;
  background-color: #fff;
  padding: $box-padding;
  margin: $box-margin;
  font-family: $font-family-main;
  border: 1px solid $purple;

  position: relative;
  right: 0;

  .main-toggle-legend-text {
    color: $purple;
    margin-top: 30px;
    .header {
      font-weight: $font-bold;
    }
  }

  @include media("<=1250px") {
    width: auto;
    margin-left: 10px;
    @media print {
      width: auto;
    }
  }

  .chart-title {
    font-size: $font-size-large;
    color: $font-color-primary;
    font-weight: $font-bold;
    margin: 20px 0;
  }

  .main-toggle-content {
    display: flex;
    flex-direction: column;

    .main-toggle-inner-content {
      // margin: 20px auto;
      margin: 0px auto;
    }
  }

  @include media("<phone") {
    width: 400px;
    left: -200px;
  }
}
